import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/utilities-power/*" />
    <RAC path="/zh-cn/utilities-power/*" />
    <RAC path="/zh-tw/utilities-power/*" />
    <RAC path="/pl/utilities-power/*" />
    <RAC path="/ru/utilities-power/*" />
    <RAC path="/it/utilities-power/*" />
    <RAC path="/de/utilities-power/*" />
    <RAC path="/es/utilities-power/*" />
    <RAC path="/fr/utilities-power/*" />
    <RAC path="/ar/utilities-power/*" />
    <RAC path="/id/utilities-power/*" />
    <RAC path="/pt/utilities-power/*" />
    <RAC path="/ko/utilities-power/*" />
    <DynamicNotFoundPage default />
  </Router>
)
